.list{
  width: 100%;
  position: absolute;
  bottom: -60%;
  left: 0;
  background: #fff;
  color: #080710;
  box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease-out;
  border-radius: 10px;
  z-index: 10;
}

.list.show{
  bottom: 0;
}

.list .header{
  width: 100%;
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.list .header i{
  font-size: 1.7rem;
}

.list .header > div{
  display: flex;
  align-items: center;
  gap: 5px;
}

.list .header > div span{
  font-size: 1.25rem;
  text-transform: capitalize;
  color: #444;
}

.list ul{
  width: 100%;
  height: 250px;
  overflow-y: scroll;
}

.list ul::-webkit-scrollbar{
  width: 0px;
}

.list ul li{
  width: 100%;
  padding: 10px 30px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  border-bottom: 1px solid #eee;
}

.list ul li:hover{
  background: palegoldenrod;
}

.list ul li .row p{
  color: #999;
  font-size: 0.85rem;
}

.list .duration{
  font-size: 0.9rem;
}

.list ul li.playing{
  background: papayawhip;
}